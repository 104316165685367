<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron líneas de escandallo"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :toolbar-options="toolbarOptions"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="lorden_trabajo_detalle.idlorden_trabajo_detalle"
      @click-toolbar-option="clickToolbarOption"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <lorden-trabajo-detalle-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <lorden-trabajo-detalle-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import LordenTrabajoDetalleListItem from './components/LordenTrabajoDetalleListItem'
import LordenTrabajoDetalleListLegend from './components/LordenTrabajoDetalleListLegend'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineLordenTrabajoDetalleList'

export default {
  components: {
    LordenTrabajoDetalleListItem, LordenTrabajoDetalleListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      const LineaOrdenTrabajo = await this.$offline.lordenTrabajo.row(this.routeParams.idlorden_trabajo)
      this.title = `Escandallo de ${LineaOrdenTrabajo.lorden_trabajo.descripcion}`
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await this.$offline.lordenTrabajoDetalle.selectEscandalloDeIdlordenTrabajo(
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idlorden_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await this.$offline.lordenTrabajoDetalle.selectEscandalloDeIdlordenTrabajoRows(dirty.modified), dirty, 'lorden_trabajo_detalle.idlorden_trabajo_detalle'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__lorden-trabajo-detalle-view',
        params: {
          idorden_trabajo: this.routeParams.idorden_trabajo,
          idlorden_trabajo: this.routeParams.idlorden_trabajo,
          idlorden_trabajo_detalle: item.data.lorden_trabajo_detalle.idlorden_trabajo_detalle,
        },
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar el artículo "${data.item.lorden_trabajo_detalle.descripcion}"?`)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$offline.lordenTrabajoDetalle.deleteSync(data.item.lorden_trabajo_detalle.idlorden_trabajo_detalle)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  }
}
</script>
